<script>
import { authMethods } from "@/state/helpers";
import appConfig from "../../../app.config";
import Swal from "sweetalert2";
  import axios from 'axios';
import { required, email, helpers } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import Lottie from "@/components/widgets/lottie.vue";
import serverConfig   from '@/helpers/config';
import animationData from "@/components/widgets/rhvddzym.json";
export default {
  components: { lottie: Lottie },
  setup() {
    const backgroundImg = `url('${serverConfig.EP}public/assets/img/background-logo.png')`

    let tk = 'Bearer '+ localStorage.getItem('tk')
    return { v$: useVuelidate(), tk, backgroundImg };
  },
  validations: {
    email: {
      required: helpers.withMessage("Email obbligatoria", required),
      email: helpers.withMessage("Per favore, inserisci una email valida", email),
    },
  },
  page: {
    title: "Password dimenticata",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      config:serverConfig,
      email: "",
      submitted: false,
      error: null,
      tryingToReset: false,
      isResetError: false,
      defaultOptions: { animationData: animationData },
    };
  },
  mounted(){
    document.getElementById('auth-particles').style.backgroundImage = this.backgroundImg;
  },
  methods: {
    ...authMethods,
    // Try to register the user in with the email, fullname
    // and password they provided.
    tryToReset() {
      this.submitted = true;
      // stop here if form is invalid
      this.v$.$touch();

      if (this.v$.$invalid) {
        return;
      } else {
        if (this.email!='') {
          
          axios.post(`${serverConfig.EP}users/reset-password`, {email:this.email}, {headers: { authorization:this.tk}} )
          .then(()=>{
          
            Swal.fire(  this.$t("t-msg-re-psw") , this.$t("t-msg-re-psw-content"), "success");
            
          })
          .catch(()=>{
              Swal.fire( this.$t("t-msg-error") , this.$t("t-msg-error-content"), "error");
          });
        } else {
          Swal.fire(  this.$t("t-msg-re-psw-no-data") , this.$t("t-msg-re-psw-content-no-data"), "warning");
        }
      }
    },
  },
};
</script>

<template>
  <div class="auth-page-wrapper pt-5">
    <div class="auth-one-bg-position auth-one-bg" id="auth-particles">
      <div class="bg-overlay"></div>
    </div>
    <div class="auth-page-content">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="text-center mt-sm-5 mb-4 text-white-50">
              <div>
                <router-link to="/" class="d-inline-block auth-logo">
                  <img :src="config.EP+'/public/assets/img/logo-light.png'" alt="" :height="config.IMG_HEIGHT" />
                </router-link>
              </div>
              <p class="mt-3 fs-15 fw-medium">
              </p>
            </div>
          </div>
        </div>
        <!-- end row -->

        <div class="row justify-content-center">
          <div class="col-md-8 col-lg-6 col-xl-5">
            <div class="card mt-4">
              <div class="card-body p-4">
                <div class="text-center mt-2">
                  <h5 class="text-primary">{{ $t('t-as-forgot-password') }}</h5>
                  <p class="text-muted">{{ $t('t-as-reset-password-with')}}</p>

                   <lottie
                      class="avatar-xl"
                      colors="primary:#45CB85,secondary:#4b38b3"
                      :options="defaultOptions"
                      :height="120"
                      :width="120"
                    />
                  
                </div>

                <div class="p-2">
                  <b-alert
                    v-model="isResetError"
                    class="mb-4"
                    variant="danger"
                    dismissible
                    >{{ error }}</b-alert
                  >
                  <form @submit.prevent="tryToReset">
                    <div class="mb-4">
                      <label class="form-label">Email</label>
                      <input
                        type="email"
                        v-model="email"
                        class="form-control"
                        id="email"
                        :class="{ 'is-invalid': submitted && v$.email.$errors }"
                        placeholder="Inserisci Email"
                      />
                      <div
                        v-for="(item, index) in v$.email.$errors"
                        :key="index"
                        class="invalid-feedback"
                      >
                        <span v-if="item.$message">{{ item.$message }}</span>
                      </div>
                    </div>

                    <div class="text-center mt-4">
                      <button class="btn btn-primary w-100" type="submit">
                       Richiedi link per rinnovo password
                      </button>
                    </div>
                  </form>
                </div>
              </div>
              <!-- end card body -->
            </div>
            <!-- end card -->

            <div class="mt-4 text-center">

            </div>
          </div>
        </div>
        <!-- end row -->
      </div>
      <!-- end container -->
    </div>
    <!-- end auth page content -->

    <!-- footer -->
    <footer class="footer">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="text-center">
              <p class="mb-0 text-muted">
                &copy; {{ new Date().getFullYear() }} 
               <a href="https://www.neuralika.com" target="blank">Neuralika</a> 
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
    <!-- end Footer -->
  </div>
</template>
